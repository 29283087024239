import React, { useState } from "react";
import "./App.css";

const App = () => {
  // State to control the visibility of the mobile menu
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close the menu when a link is clicked
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      {/* Header */}
      <header className="navbar">
        <div className="container">
          <a href="/" className="logo">
            <span className="logo-main">KUBICL</span>
            <span className="logo-sub">TECHNOLOGIES</span>
          </a>
          {/* Hamburger icon */}
          <div className="hamburger" onClick={toggleMenu}>
            <div className={menuOpen ? "bar open" : "bar"}></div>
            <div className={menuOpen ? "bar open" : "bar"}></div>
            <div className={menuOpen ? "bar open" : "bar"}></div>
          </div>
          {/* Navigation */}
          <nav className={menuOpen ? "nav active" : "nav"}>
            <ul>
              <li>
                <a href="#about" onClick={closeMenu}>
                  About
                </a>
              </li>
              <li>
                <a href="#services" onClick={closeMenu}>
                  Services
                </a>
              </li>
              <li>
                <a href="#contact" onClick={closeMenu}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="container hero-content">
          <h1>Empowering Your DevOps Journey</h1>
          <p>
            Seamless integration of software development and IT operations for
            faster delivery.
          </p>
          <a href="#services" className="cta-button">
            Learn More
          </a>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="content-section">
        <div className="container">
          <h2>About Us</h2>
          <div className="about-content">
            <div className="about-text">
              <p>
                Kubicl Technologies Pvt. Ltd. specializes in providing top-notch
                DevOps solutions. We ensure seamless integration of software
                development and IT operations for our clients. With our
                expertise, we help businesses achieve faster development cycles,
                automated workflows, and efficient cloud-native infrastructures.
              </p>
            </div>
            <div className="about-image">
              <img
                src="https://img.freepik.com/premium-vector/programmers-practice-development-software-operations-devops-concept_773186-1450.jpg?w=900"
                alt="About us"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="content-section services">
        <div className="container">
          <h2>Our Services</h2>
          <div className="services-list">
            <div className="service">
              <h3>CI/CD Pipeline Setup</h3>
              <p>
                Automate your software deployment and reduce errors with
                continuous integration and delivery pipelines.
              </p>
            </div>
            <div className="service">
              <h3>Infrastructure as Code (IaC)</h3>
              <p>
                Use Terraform and CloudFormation to provision and manage
                infrastructure.
              </p>
            </div>
            <div className="service">
              <h3>Cloud Solutions</h3>
              <p>
                Design, build, and manage cloud infrastructures with AWS, Azure,
                and GCP.
              </p>
            </div>
            <div className="service">
              <h3>Monitoring & Logging</h3>
              <p>
                Set up tools like Prometheus, Grafana, and ELK Stack to monitor
                and log applications and systems.
              </p>
            </div>
            <div className="service">
              <h3>Security in DevOps (DevSecOps)</h3>
              <p>
                Implement security practices within the DevOps pipeline to
                ensure secure code delivery.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="content-section contact">
        <div className="container">
          <h2>Contact Us</h2>
          <p>
            Ready to accelerate your DevOps transformation? Get in touch with us
            today to discuss your project needs.
          </p>
          <p>
            Reach us at <a href="mailto:info@kubicl.com">info@kubicl.com</a>
          </p>
        </div>
      </section>

      {/* Footer */}
      <footer>
        <div className="footer-content">
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg"
              alt="LinkedIn"
            />
          </a>
          <p>&copy; 2024 Kubicl Technologies Pvt. Ltd. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default App;
